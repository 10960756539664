<template>
  <button
    type="button"
    class="flex relative px-2 py-0-5 rounded-full text-left text-white text-xxs font-semibold uppercase whitespace-nowrap"
    :class="[
      dark ? 'bg-headline' : 'bg-disabled-medium',
      disabled ? 'cursor-default' : 'cursor-pointer'
    ]"
    @click.stop="toggleMenu"
    ref="dropdownButton"
    :disabled="disabled"
  >
    {{ label }}
    <span
      v-if="!disabled"
      class="ml-2 inset-y-0 flex place-self-center cursor-pointer"
    >
      <icon-base
        height="6"
        width="10"
        icon="dropdown-arrow"
        iconColor="#FFFFFF"
      />
    </span>
  </button>

  <teleport to="#menus">
    <div
      v-if="showMenu"
      v-on-click-outside="onClickOutsideHandler"
      class="absolute mt-1 z-30 left-0 w-max"
      :style="{
        top: `${top}px`,
        left: `${left}px`
      }"
    >
      <ul
        role="listbox"
        class="max-h-60 overflow-y-auto bg-white shadow-lg rounded-md pt-2-5 text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <li
          v-for="(option, id) in SF_WORKFLOWS"
          :key="id"
          class="px-5 py-2-5 hover:bg-gray-200 flex flex-grow-1 justify-start overflow-auto"
          role="option"
        >
          <lf-checkbox
            name="workflow"
            :value="id"
            v-model="checkboxValue"
            @change="handleCheckboxChange"
            :disabled="model.status === DO_SAVE_TEMPLATE_MODES.publish"
          >
            <span class="font-normal text-left">{{ option }}</span>
          </lf-checkbox>
        </li>
      </ul>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref, type Ref } from "vue";
import { useStore } from "vuex";
import isEqual from "lodash/isEqual";

import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import { SF_WORKFLOWS, DO_SAVE_TEMPLATE_MODES } from "@/helpers/constants";
import type { IOrchestrationTemplate } from "@/models/orchestration";
import { useTeleport } from "@/hooks/elements";

const MAX_SELECTED_DISPLAYED = 2;

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    // added String to type because query-string library type is being parsed wrong
    type: [Array, String] as PropType<(number | string)[]>,
    required: true
  },
  model: {
    type: Object as PropType<IOrchestrationTemplate>,
    required: true
  },
  dark: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  value: {
    // ------------------------------
    // to remove warnings from console
    type: [String, Number, Object]
    // ------------------------------
  }
});

const { dispatch } = useStore();
const showMenu = ref(false);
const checkboxValue = ref((props.modelValue || []).map(String));
const initial = ref(props.modelValue);
const dropdownButton: Ref<HTMLInputElement | null> = ref(null);

const { top, left } = useTeleport(
  dropdownButton,
  "clientMenuButtonParent",
  "menu",
  null,
  "orchestration-table"
);

const label = computed(() => {
  const selectedCount = props.modelValue?.length || 0;
  if (!selectedCount) {
    return "Select workflow";
  }
  if (selectedCount === Object.keys(SF_WORKFLOWS).length) {
    return "All Workflows";
  }
  if (selectedCount < MAX_SELECTED_DISPLAYED) {
    return props.modelValue
      .map((id) => SF_WORKFLOWS[id as keyof typeof SF_WORKFLOWS])
      .join(", ");
  }
  return `${selectedCount} workflows`;
});

const toggleMenu = () => {
  if (!showMenu.value) {
    document.body.click();
  }
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  // We don't want to call this everytime it's clicked outside the element
  // only if the menu is open
  if (!showMenu.value) {
    return;
  }
  showMenu.value = false;
  // Make the update request only if values are changed
  if (isEqual(initial.value.map(String), checkboxValue.value)) {
    return;
  }
  updateWorkflow(checkboxValue.value);
  initial.value = checkboxValue.value;
};

const handleCheckboxChange = () => {
  emit("update:modelValue", checkboxValue.value);
};

const updateWorkflow = (newWorkflow: string[]) => {
  dispatch("orchestration/updateTemplate", {
    data: {
      workflow: newWorkflow
    },
    templateId: props.model?.id
  });
};

const onClickOutsideHandler = [closeMenu, { capture: false }];
</script>
